import './accordion.scss';

class Accordion {
    constructor (element) {
        this.$accordion = element;

        // this.$accordionHeaders = this.$accordion.querySelectorAll('.accordion__item-header');
        this.$accordionHeaders = this.$accordion.querySelectorAll('[data-accordion="header"]');
        // this.$accordionItems = this.$accordion.querySelectorAll('.accordion__item');
        this.$accordionItems = this.$accordion.querySelectorAll('[data-accordion="item"]');
        this.$accordionContents = this.$accordion.querySelectorAll('[data-accordion="content"]');
        this.$accordionType = this.$accordion.getAttribute('data-accordion-type');
        // this.$accordionContents = this.$accordion.querySelectorAll('.accordion__item-content');
    }

    initialize () {
        if (this.$accordion) {
            this.setEvents();
            this.setInitialOpenAccordion();
        }
    }

    setEvents () {
        let activeAnimation = false;

        for (let i = 0; i < this.$accordionHeaders.length; i++) {
            const $accordionHeader = this.$accordionHeaders[i];

            $accordionHeader.addEventListener('click', (e) => {
                if (!activeAnimation) {
                    activeAnimation = true;

                    const $accordionItem = $accordionHeader.parentElement;
                    // const $accordionContent = $accordionItem.querySelector('.accordion__item-content');
                    const $accordionContent = $accordionItem.querySelector('[data-accordion="content"]');
                    // const $accordionContentInner = $accordionItem.querySelector('.accordion__item-content-inner');
                    const $accordionContentInner = $accordionItem.querySelector('[data-accordion="inner"]');
                    const contentHeight = $accordionContentInner.offsetHeight;
                    const contentContainerHeight = $accordionContent.offsetHeight;
                    if (contentContainerHeight > 0) {
                        $accordionContent.style.maxHeight = contentHeight + 'px';
                        $accordionItem.classList.remove('is--active');

                        setTimeout(() => {
                            $accordionContent.style.maxHeight = '0';
                        }, 50);

                        setTimeout(() => {
                            activeAnimation = false;
                        }, 300);
                    } else {
                        if (this.$accordionType === 'automatic') {
                            for (let j = 0; j < this.$accordionItems.length; j++) {
                                if (this.$accordionItems[j] !== $accordionItem && this.$accordionItems[j].classList.contains('is--active')) {
                                    // this.$accordionItems[j].classList.add('has--no-animation');
                                    this.$accordionContents[j].style.maxHeight = contentHeight + 'px';
                                    this.$accordionItems[j].classList.remove('is--active');

                                    setTimeout(() => {
                                        this.$accordionContents[j].style.maxHeight = '0';
                                    }, 50);

                                    // setTimeout(() => {
                                    // this.$accordionItems[j].classList.remove('has--no-animation');
                                    // }, 1000);
                                }
                            }
                        }
                        $accordionContent.style.maxHeight = contentHeight + 'px';
                        $accordionItem.classList.add('is--active');

                        setTimeout(() => {
                            $accordionContent.style.maxHeight = 'none';
                            activeAnimation = false;
                        }, 300);
                    }
                }
            });
        }
    }

    setInitialOpenAccordion () {
        for (let i = 0; i < this.$accordionItems.length; i++) {
            const $accordionItem = this.$accordionItems[i];
            if ($accordionItem.getAttribute('data-accordion-initial') !== null) {
                const $accordionHeader = $accordionItem.querySelector('[data-accordion="header"]');
                $accordionHeader.click();
            }
        }
    }
}

export { Accordion };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $accordions = $context.querySelectorAll('[data-accordion="root"]');
        if ($accordions.length > 0) {
            for (let i = 0; i < $accordions.length; i++) {
                const accordionAPI = new Accordion($accordions[i]);
                accordionAPI.initialize();
            }
        }
    }
});
